"use strict";
'use es6';
/*

  The back/forward cache in browsers caches the state of the page
  and reuses that state when using the back/forward buttons in the browser.
  This can cause a leak of data after a user logs out. For now we are
  just forcing the page to reload as if it was not cached.

*/

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

function _default() {
  window.addEventListener('pageshow', function (event) {
    try {
      if (event.persisted) {
        var enviroModule = require('enviro');

        var env = enviroModule.default || enviroModule;
        var hublet = env.getHublet();
        window.navigator.sendBeacon("https://api" + (hublet === 'na1' ? '' : "-" + hublet) + ".hubspot" + (env.getShort() === 'qa' ? 'qa' : '') + ".com/cartographer/v1/count/persisted-page-view");
        window.location.reload();
      }
    } catch (e) {// Ignore
    }
  });
}

module.exports = exports.default;